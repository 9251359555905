<template>
  <v-container>
    <h1 class="my-4 heading-3">
      Documentation
    </h1>
    <h2 class="heading-5 my-4">
      Algorithme de sélection d'une grille tarifaire
    </h2>
    <p>Si une adresse appartient à deux zones tarifaires, c'est la plus petite zone (aire en m2) qui sera sélectionnée.</p>
    <h2 class="heading-5 my-4">
      Algorithme de calcul : surface * (prix m2 + options)
    </h2>
    <p>On multiplie la surface par le prix au m2 + le total des options.<br> Si l'addition du prix au m2 et des options est inférieure à 1 500, la valeur par défaut sera 1 500</p>
    <h2 class="heading-5 my-4">
      Récapitulatif des variables d'estimation et des données associées
    </h2>
    <v-treeview
      open-all
      :items="items"
    />
  </v-container>
</template>

<script>

import { mapState } from 'vuex'

export default {
  name: 'AdminDocumentation',
  data () {
    return {
      items: [
        {
          id: 1,
          name: 'Type',
          children: [
            {
              name: 'Maison : variable',
              value: 'house'
            },
            {
              name: 'Appartement : variable',
              value: 'apartment'
            }
          ]
        },
        {
          id: 8,
          name: 'Atouts du bien',
          children: [
            {
              name: 'Piscine : 15 000'
            },
            {
              name: 'Vue : variable'
            }
          ]
        },
        {
          id: 2,
          name: 'État',
          children: [
            {
              name: 'Neuf : 0'
            },
            {
              name: 'À rénover : -1000'
            },
            {
              name: 'À rafraîchir : -500'
            }
          ]
        },
        {
          id: 3,
          name: 'Ancienneté',
          children: [
            {
              name: 'Ancien (avant 1959) : 0'
            },
            {
              name: 'Semi-récent (1960-1979) : -700'
            },
            {
              name: 'Récent (1980 - 1999) : -400'
            },
            {
              name: 'Contemporain : -200'
            }
          ]
        },
        {
          id: 4,
          name: 'Extérieur',
          children: [
            {
              name: 'Sans extérieur : variable'
            },
            {
              name: 'Balcon : -500'
            },
            {
              name: 'Terrasse : 0'
            },
            {
              name: 'Jardin : 100'
            },
            {
              name: 'Parc (supérieur à 1000m2) : 400'
            }
          ]
        },
        {
          id: 5,
          name: 'Calme',
          children: [
            {
              name: 'Bien : 0'
            },
            {
              name: 'Moyen : -200'
            },
            {
              name: 'Passable : -600'
            }
          ]
        },
        {
          id: 6,
          name: 'Ensoleillement',
          children: [
            {
              name: 'Bien : 0'
            },
            {
              name: 'Moyen : -200'
            },
            {
              name: 'Passable : -600'
            }
          ]
        },
        {
          id: 7,
          name: 'Vis à vis',
          children: [
            {
              name: 'Bien : 0'
            },
            {
              name: 'Moyen : -200'
            },
            {
              name: 'Passable : -600'
            }
          ]
        }
      ]
    }
  },
  computed: {
    ...mapState(['options'])
  }
}
</script>
